import React from 'react';

class ParkingSpace extends React.Component {
    render(props){
        return (
          <li className="list-group-item d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">
              <div className="fw-bold">{this.props.data.name}</div>
              {this.props.data.owner}
            </div>
            {
                this.props.data.isAvailable &&
                <i className="bi bi-plus-circle"></i>
            }
            { 
                this.props.data.isReleasable && 
                <i className="bi bi-trash"></i>
            }
          </li>
        );
    }
}

export default ParkingSpace;