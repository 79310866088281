import React from 'react';

class DateChooser extends React.Component {
    formatDate(date){
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('de-DE', options);
    }

    render(){
        return (
            <div>
                <i className="bi bi-caret-left-fill" onClick={this.props.onClickPrevDay}></i> 
                {this.formatDate(this.props.date)} 
                <i className="bi bi-caret-right-fill" onClick={this.props.onClickNextDay}></i>
            </div>
        );
    }    
}

export default DateChooser;