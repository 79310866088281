import React from 'react';
import './App.css';
import DateChooser from './DateChooser';
import ParkingSpace from './ParkingSpace';

class App extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      "currentUser":"1",
      "date": new Date(),
      "spaces": []
    };

    this.handleClickPrevDay = this.handleClickPrevDay.bind(this);
    this.handleClickNextDay = this.handleClickNextDay.bind(this);
  }

  componentDidMount() {
    fetch("http://api.lembkenet.de/spaces/"+this.state.date.toISOString().replace(/T.*/,'').split('-').reverse().join('-'))
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            spaces: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
        }
      )
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.date.getTime() !== prevState.date.getTime()) {
      fetch("http://api.lembkenet.de/spaces/"+this.state.date.toISOString().replace(/T.*/,'').split('-').reverse().join('-'))
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              spaces: result
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error);
          }
        )
    }
  }

  handleClickPrevDay(){
    const newDate = new Date(this.state.date);
    newDate.setDate(newDate.getDate() - 1);
    this.setState({
        date : newDate
    });
  }

  handleClickNextDay(){
    const newDate = new Date(this.state.date);
    newDate.setDate(newDate.getDate() + 1);
    this.setState({
        date : newDate
    });
  }

  render () {
    return (
      <div className="App">
        <p className="h4">Hallo Simon</p>
        <DateChooser onClickPrevDay={this.handleClickPrevDay} onClickNextDay={this.handleClickNextDay} date={this.state.date}/>
        <ul className="list-group">
          {this.state.spaces.map((element) => {
            const data = {
              "name":element.name,
              "owner":element.owner,
              "isAvailable":element.ownerId === undefined,
              "isReleasable":element.ownerId === this.state.currentUser
            };
            return <ParkingSpace key={element.id} data={data}/>
          })}
        </ul>
      </div>
    );
  }
}

export default App;
